import React, { useContext } from "react"

import MobileWindow from "./MobileWindow"
import LinkButtonMobile from "../LinkButtonMobile"
import { AppContext } from "../../context/AppContext"

import links from "../Windows/utils/linksList.json"

const LinksPage = () => {
    let { state } = useContext(AppContext)

    return (
        <MobileWindow title="links">
            <div className="mt-5 is-flex is-justify-content-center is-flex-direction-column">
                {links.slice(0,8).map((link) => (
                    <div key={link.label}>
                        <LinkButtonMobile
                            image={{
                                src: state.isDark ? link.image_dark : link.image,
                                alt: link.label
                            }}
                            label={link.label}
                            link={link.link}
                        />
                        <hr className="has-text-primary my-0 mx-4"/>
                    </div>
                ))}
            </div>
        </MobileWindow>
    )
}
export default LinksPage