import React from "react"
import classNames from "classnames"
import useSound from "use-sound"
import { isBrowser } from "../services/general"

import * as styles from "./utils/component.module.scss"
import clickSfx from "../../static/sounds/click_general.mp3"

const LinkButtonMobile = ({ image, label, link }) => {
    const [playClickSound] = useSound(clickSfx, {volume: 0.6})
    return(
        <button
            onClick={() => {
                if (isBrowser()) window.open(link, "_blank", "noopener")
            }}
            onMouseUp={playClickSound}
            className={classNames(styles["iconButton"])}
        >
            <div className="media is-align-items-center m-3">
                <img
                    draggable={false}
                    src={image?.src}
                    alt={`${image.alt}`}
                    className={classNames(styles["iconImage"], "media-left")}
                />
                <p className="media-content has-text-weight-bold">
                    {label}
                </p>
            </div>
        </button>
    )
}

export default LinkButtonMobile