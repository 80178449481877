// extracted by mini-css-extract-plugin
export var downloadsButton = "component-module--downloads-button--c94fb";
export var downloadsIcon = "component-module--downloads-icon--0d2d6";
export var downloadsText = "component-module--downloads-text--49bec";
export var footerLinkButton = "component-module--footer-link-button--48e3e";
export var footerLinkImage = "component-module--footer-link-image--ace3a";
export var footer__copyright = "component-module--footer__copyright--1bbad";
export var iconButton = "component-module--icon-button--141a6";
export var iconImage = "component-module--icon-image--00cb9";
export var iconLabel = "component-module--icon-label--06388";
export var window = "component-module--window--6b59a";
export var window__contact = "component-module--window__contact--638ea";
export var window__links = "component-module--window__links--26554";
export var window__success = "component-module--window__success--9ed26";
export var window__successHeader = "component-module--window__success-header--8f9d1";
export var window__successX = "component-module--window__success-x--20b62";
export var window__titleText = "component-module--window__title-text--b34f3";
export var window__titleX = "component-module--window__title-x--0b472";